const ignoreFields = ['category'];

const paletteColors = [
  ['Black', '101010'],
  ['Taupe', 'b09c82'],
  ['Gray', '808080'],
  ['Silver', 'c0c0c0'],
  ['Ivory', 'f5f5dc'],
  ['White', 'ffffff'],
  ['Burgundy', '750f17'],
  ['Red', 'c00718'],
  ['Terracotta', 'b24d38'],
  ['Coral', 'f77564'],
  ['Orange', 'fa760a'],
  ['Peach', 'fbbd93'],
  ['Brown', '4f2907'],
  ['Dark Green', '204b21'],
  ['Olive', '808000'],
  ['Green', '248a0f'],
  ['Yellow', 'ffd246'],
  ['Gold', 'deaa0d'],
  ['Navy', '132b53'],
  ['Blue', '1f5e9e'],
  ['Teal', '008080'],
  ['Aqua', '55c2c3'],
  ['Light Blue', '90c1e4'],
  ['Purple', '67357e'],
  ['Pink', 'eb6fa4'],
  ['Beige', 'dac8a0'],
];

export default {
  platform: 'bigcommerce',
  defaultView: 'productGrid',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 48,
  },
  ColorEngineFeature: true,
  facets: {
    advancedColorFacet: { fields: ['_Color'], paletteColors },
    switchFacet: {
      fields: ['Size'],
      childFields: ['Width', 'Length'],
      singleFacet: true,
      step: 'imperial',
      getInitCase: () => (/([?/])(Width|Length)\//.test(window.location.search) ? 1 : 0),
      cases: [{ type: 'simpleFacet' }, { type: 'sizeFacet' }],
    },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'colorSearch/searchPage',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: 'body.brand #product-listing-container',
      template: 'MainContent',
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: { replace: 'body.category #colm-left .page' },
      template: 'FacetPanelContainer',
      needInitRequest: true,
      ignoreFields,
    },
    {
      name: 'FacetPanelBrand',
      type: 'FacetPanel',
      location: { replace: 'body.brand #colm-left .page' },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'date_created:desc': 'Newest',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'review_score:desc': 'Customer Review',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'SearchBox',
      location: { replace: '.header-top .wbSearch form', class: 'form cm_search-box-root' },
      template: 'SearchBox',
      onSubmit: () => window.document.querySelector('.header-top .wbSearch .closebtn')?.click(),
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.header-top .mobile-search form',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetDialogButton',
      type: 'DialogButton',
      location: { firstChildOf: 'body.category #product-listing-container' },
      dialogName: 'FacetDialog',
      template: 'FacetDialogButton',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'SimpleColorPaletteDialog',
      field: '_Color',
      template: 'colorSearch/simplePaletteDialog',
    },
    {
      name: 'ColorExtractorDialog',
      field: '_Color',
      template: 'colorSearch/colorExtractorDialog',
    },
  ],
};
