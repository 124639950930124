//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-452:_4224,_5852,_6784,_9928,_9968,_7216,_228,_3264;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-452')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-452', "_4224,_5852,_6784,_9928,_9968,_7216,_228,_3264");
        }
      }catch (ex) {
        console.error(ex);
      }