import { SearchPage } from 'Components'
import TemplatecolorSearchsearchPage from 'Stores/_default-store/Templates/colorSearch/searchPage.rt'
import TemplateMainContent from 'Stores/paylessrugs-bigcommerce/Templates/MainContent.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/paylessrugs-bigcommerce/Templates/FacetPanelContainer.rt'
import { SearchHeader } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { DialogButton } from 'Components'
import TemplateFacetDialogButton from 'Stores/paylessrugs-bigcommerce/Templates/FacetDialogButton.rt'
import { SearchResult } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { SimpleColorPaletteDialog } from 'Components'
import TemplatecolorSearchsimplePaletteDialog from 'Stores/_default-store/Templates/colorSearch/simplePaletteDialog.rt'
import { ColorExtractorDialog } from 'Components'
import TemplatecolorSearchcolorExtractorDialog from 'Stores/_default-store/Templates/colorSearch/colorExtractorDialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchHeader,
SearchBox,
SearchBoxDialogButton,
DialogButton,
SearchResult,
FacetDialog,
SearchBoxDialog,
SimpleColorPaletteDialog,
ColorExtractorDialog
} 
export const tplMap = {
  'colorSearch/searchPage': TemplatecolorSearchsearchPage,
'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'FacetDialogButton': TemplateFacetDialogButton,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'colorSearch/simplePaletteDialog': TemplatecolorSearchsimplePaletteDialog,
'colorSearch/colorExtractorDialog': TemplatecolorSearchcolorExtractorDialog
};