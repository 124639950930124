
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetPanelContainerRT () {
    return _createElement('div', { 'className': 'cm_FacetPanel cm_facet-panel__full-width' }, [[this.filterChips(function () {
                function repeatChips1(chips, chipsIndex) {
                    return [chips(function () {
                            function scopeDollarInchMm1_rangeChip() {
                                var dollar = [
                                    'price',
                                    'Price'
                                ].includes(this.field) ? '$' : '';
                                var inch = [].includes(this.field) ? '"' : '';
                                var mm = [
                                    'wheel_bore',
                                    'wheel_offset'
                                ].includes(this.field) ? 'mm' : '';
                                return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, '\n    ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  ') : null, this.toPosInf ? _createElement(_Fragment, { 'key': '626' }, ' ', dollar, this.selection[0], inch, mm, ' and more ') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '732' }, ' ', dollar, this.selection[1], inch, mm, ' and less ') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '8680'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                            }
                            return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-times',
                                    'height': '20px',
                                    'role': 'image',
                                    'viewBox': '0 0 22 22',
                                    'key': '2570'
                                }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : this.template === 'colorChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_colorChip' }, _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), '\n  ', this.value, '\n  ', _createElement('span', { 'className': 'cm_chip-color' }, this.colorTile, ' ', this.percent, '%')), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-times',
                                    'height': '20px',
                                    'role': 'image',
                                    'viewBox': '0 0 22 22',
                                    'key': '2640'
                                }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                    'className': 'facettitle',
                    'tabIndex': '0'
                }, 'Current search:'), _createElement('div', {
                    'className': 'facetbody',
                    'role': 'list'
                }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_chips' },
                    _map(this.chips, repeatChips1.bind(this))
                ]), _createElement('div', {
                    'className': 'cm_btn cm_button button button--primary cm_filter-chips_button',
                    'data-cm-role': 'start-over'
                }, '\n        Start over\n      '))));
            }, {
                widgetName: 'filter-chips',
                items: undefined
            })]], [this.facets(function () {
            function repeatFacets1(facets, facetsIndex) {
                return [facets(function () {
                        function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                            return [ShowAlwaysValues(function () {
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        function repeatValues2(Values, ValuesIndex) {
                            return [Values(function () {
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        function repeatValues3(Values, ValuesIndex) {
                            return [Values(function () {
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, !this.isExtraRange ? _createElement('span', { 'key': '217' }, this.value) : null, this.isExtraRange && this.finite ? _createElement('span', { 'key': '278' }, '$', this.range[0], ' - $', this.range[1]) : null, this.isExtraRange && this.toPosInf ? _createElement('span', { 'key': '394' }, '$', this.range[0], ' and more') : null, this.isExtraRange && this.toNegInf ? _createElement('span', { 'key': '497' }, '$', this.range[1], ' and less') : null), !this.isExtraRange ? _createElement('div', {
                                        'className': 'facetentryval',
                                        'key': '609'
                                    }, this.hitCount) : null);
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                            'className': 'facettitle',
                            'data-cm-role': 'toggle-facet',
                            'tabIndex': '0'
                        }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                'className': 'cm_icon cm_icon-angle left',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '39 30 565 565',
                                'key': '1630'
                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                'className': 'cm_icon cm_icon-angle down',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '39 30 565 565',
                                'key': '4650'
                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                            'className': 'facetbody',
                            'role': 'list'
                        }, this.template === 'simpleFacet' ? _createElement('div', {
                            'className': 'cmTemplate_simpleFacet',
                            'key': '821'
                        }, this.showFilterInput ? _createElement('div', {
                            'className': 'filter-input',
                            'key': '907'
                        }, [this.filterInput(function () {
                                return _createElement('div', {
                                    'className': 'input  cm_filterInput',
                                    'placeholder': 'Enter'
                                });
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })], this.inputNotEmpty ? _createElement('span', {
                            'className': 'filter-input_clear-container',
                            'onClick': this.clearInput,
                            'key': '1227'
                        }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                'className': 'facetdiv',
                                'key': '13961'
                            }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                        ]), _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_Values' },
                            _map(this.Values, repeatValues2.bind(this))
                        ]), this.needShowMore ? _createElement('div', {
                            'className': 'facetdiv cm_show-all-container',
                            'key': '3227'
                        }, _createElement('a', {
                            'className': 'cm_show-all',
                            'data-cm-role': 'toggle-show-more'
                        }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null), this.field === 'Size' ? _createElement('div', {
                            'className': 'cm_btn',
                            'data-cm-role': 'next-case',
                            'key': '3527'
                        }, 'Search specific sizes') : null) : null, this.template === 'priceFacet' ? _createElement('div', {
                            'className': 'cmTemplate_priceFacet',
                            'key': '3653'
                        }, !this.ranges.length ? [
                            _createElement('div', { 'key': '37371' }, '$', this.selection[0], ' to $', this.selection[1]),
                            '\n  ',
                            this.slider,
                            '\n  ',
                            _createElement('div', {
                                'className': 'cm_flex',
                                'key': '37373'
                            }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                        ] : null, this.ranges.length ? [_createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cmRepeater_Values',
                                    'key': '40051'
                                },
                                _map(this.Values, repeatValues3.bind(this))
                            ])] : null, [this.Inputs(function () {
                                return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Min price',
                                            'className': 'cm_inputMin'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Max price',
                                            'className': 'cm_inputMax'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], _createElement('button', {
                                    'type': 'button',
                                    'className': 'cm_btn cm_button button button--secondary',
                                    'data-cm-role': 'add-facet'
                                }, '\n    GO\n  '));
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]) : null, this.template === 'advancedColorFacet' ? _createElement('div', {
                            'className': 'cmTemplate_advancedColorFacet',
                            'key': '6550'
                        }, _createElement('div', { 'className': 'base-palette' }, '\n  ', this.paletteItems, '\n  ', _createElement('div', {
                            'className': 'color-tile icon-th',
                            'onClick': this.openSimplePaletteDialog
                        })), _createElement('div', { 'className': 'palette-buttons' }, _createElement('div', {
                            'className': 'cm_btn',
                            'onClick': this.openColorExtractorDialog
                        }, 'Upload Your Color')), '\n', this.sliders, '\n') : null, this.template === 'sizeFacet' ? _createElement('div', {
                            'className': 'cmTemplate_sizeFacet',
                            'key': '6936'
                        }, [this.WidthFacet(function () {
                                return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'subsize' }, _createElement('span', { 'className': 'subsize-title' }, this.field, ':'), !this.isNullRange ? _createElement('span', { 'key': '102' }, this.selection[0], ' - ', this.selection[1]) : null, this.isNullRange ? _createElement('span', { 'key': '185' }, this.selection[0]) : null, '\n  ', this.slider, '\n'));
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })], [this.LengthFacet(function () {
                                return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'subsize' }, _createElement('span', { 'className': 'subsize-title' }, this.field, ':'), !this.isNullRange ? _createElement('span', { 'key': '103' }, this.selection[0], ' - ', this.selection[1]) : null, this.isNullRange ? _createElement('span', { 'key': '186' }, this.selection[0]) : null, '\n  ', this.slider, '\n'));
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })], _createElement('div', {
                            'className': 'cm_btn',
                            'data-cm-role': 'next-case'
                        }, 'Search popular sizes')) : null));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_facets' },
                _map(this.facets, repeatFacets1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets"]